var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.userIsAuthenticated)?_c('div',{staticClass:"m-auth-container px-5",class:{
    [_vm.block?.containerClass]: true,
  },style:({
    backgroundColor: _vm.block?.componentStyle?.backgroundColor,
    paddingTop: _vm.block?.componentStyle?.paddingTop + 'px',
    paddingBottom: _vm.block?.componentStyle?.paddingBottom + 'px',
  })},[_c('div',{staticClass:"register-container d-flex flex-column flex-xl-row mx-auto justify-center justify-xl-space-between"},[(_vm.shouldRender)?_c('authorization-module',{staticClass:"m-auth-form",attrs:{"registrationObject":_vm.registrationObject},on:{"authButton":_vm.authBtnPressed,"valueChanged":_vm.valueChanged,"showLogin":_vm.showLogin}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"register-style d-flex flex-column"},[_c('h2',{staticClass:"register-title",domProps:{"innerHTML":_vm._s(_vm.block?.title)}}),_vm._v(" "),(_vm.block?.image)?_c('div',{staticClass:"register-image d-none d-xl-flex"},[_c('nuxt-img',{attrs:{"src":_vm.block?.image,"loading":"lazy","alt":"register-background-image"}})],1):_vm._e()])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }