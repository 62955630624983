
import Vue, { PropType } from 'vue'
import CustomScheme from '../../schemes/customScheme'
import { Block } from '@interfaces/BlockTypes'
export default Vue.extend({
  name: 'CommonHomeRegister',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },
  data() {
    return {
      registrationObject: {
        environment: this.$config.environment,
        compact: true,
        appConfig: {
          registerUrl: `${this.$config.apiBaseURL}/oauth2/register`,
          environment: this.$config.environment,
          community_url: '',
          appUrl: this.$config.apiBaseURL,
        },
        email: '',
        password: '',
        errorMessage: '',
        loading: false,
        registerFields: [] as any[],
        showErrorMessage: false,
        showGoogle: true,
        successMessage: '',
        translatables: {
          registerTitle: 'Register to Machinations',
          termsOfServiceRegistration: 'I have read and agreed to the ',
          termsAndConditionsUrlLabel: 'Terms of Service',
          wordCapitalRegister: 'Create account',
          wordOr: 'or',
          registerWithGoogle: 'Continue with Google',
          registerExistingAccount: 'Already have an account ?',
          loginWithEmail: ' Log In',
        },
        validForm: false,
        reCaptchaKey: this.$config.recaptcha_site_key
      },
      shouldRender: false
    }
  },
  methods: {
    authBtnPressed(data: {
      valid: boolean
      email: string
      password: string
      recaptchaToken: string
    }) {
      if (data.valid) {
        (this.$auth.strategies.customStrategy as unknown as CustomScheme)
          .register(data.email, data.password).catch((error) => {
            this.$store.commit("common/updateErrorMessage", error.message);
          });
      }
    },
    valueChanged(data: { itemName: string; value: string }) {
      const newRegistrationObj = JSON.parse(
          JSON.stringify(this.registrationObject)
      )
      for (const [key, value] of Object.entries(newRegistrationObj)) {
        if (key === data.itemName) newRegistrationObj[key] = data.value
      }
      this.registrationObject = newRegistrationObj
    },
    showLogin() {
      this.$auth.login()
    }
  },
  computed: {
    userIsAuthenticated() {
      return this.$store.getters['userIsAuthenticated']
    },
  },
  mounted() {
    Vue.nextTick(() => {
      this.shouldRender = true
    })
  }
})
